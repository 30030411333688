<template>
  <div class="container opening-hours">
    <div class="row opening-hours-row">
      <div class="head opening-hours-head">
        <h3>
          {{$t('core.Base.Openingstijden')}}
        </h3>
      </div>
      <div class="col-12 testimonial-group-container" v-if="tabs.length > 0 ">
        <div class="testimonial-group">
          <ul class="opening-hours-filters-container text-center">
            <li class="tbdata"  v-for="( tabData, index ) in tabs " :key="index">
              <a href="#" class="category-btn" :class="{ 'active': tabData.id == selected_tab}" @click="changeTab($event, tabData)">
                {{tabData.title}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <template v-for="(hours, index) in hours_data">
        <div class="col-12 opening-hours-container"  :key="index" v-if="index == selected_tab">
          <opening-hours-table :hours="hours"></opening-hours-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import OpeningHoursTable from '../components/OpeningHoursTable.vue'
  
  export default {
    components: { OpeningHoursTable },
    name: "OpeningHours",

    computed: {
      ...mapGetters({ 
        settingsId: 'getSettingId'
       }),
    },

    data () {

      return {
        tabs: [
        ],
        hours_data: [],
        selected_tab: null
      }
    },


    mounted () {
      this.loadOpeningHours()
    },

    methods: {
      loadOpeningHours () {
        this.$root.$load(true)
        const settingsId = this.settingsId
        this.$http.post(`get-category-opening-time?setting_id=${settingsId}`)
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data.data;
              if(responseData.timetable && responseData.timetable.length){
                this.tabs = responseData.timetable.map( ( d ) => {
                  this.hours_data[d.id] = d.category_opening_times.map( ( d ) => {
                    return { 
                      ...d,
                      day: d.day.replace('.','')
                    }
                  });
                  return { 
                    id: d.id,
                    title: d.category_name,
                  }
                });
                this.selected_tab = responseData.timetable[0].id;
              }
              // this.snacks = items.filter((snack) => { return snack.type === 'snack' })
              // this.sandwiches = items.filter((sandwich) => { return sandwich.type === 'sandwich' })
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      },
      changeTab (e, tabData) {
        this.selected_tab = tabData.id;
      }
    }
  }
</script>
